.logoFix{
  width: 100%;
  margin-bottom: 10px;
}
.accordion-button{
  padding-left: 8px !important;
}
.accordion-collapse{
 background: white !important;
 background-color: white !important;
}
.other-products{
  line-height: 34px;
  font-size: 18px;
}
.navbar-brand{
  margin-top: 2px;
}
.productIcon{
  max-height: 16px;
  height: auto;
  padding-right: 8px;
}
